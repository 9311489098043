class Service {
  // protected server_url = process.env.REACT_APP_SERVER_URL ;
  // protected server_url_endpoint = process.env
  //   .REACT_APP_SERVER_URL_ENDPOINT ;
  app_domain = process.env.REACT_APP_DOMAIN;
  server_domain = process.env.REACT_APP_SERVER_DOMAIN;
  server_domain_endpoint = process.env.REACT_APP_SERVER_DOMAIN_ENDPOINT;
  log_in = `${this.server_domain_endpoint}/login`;
  walls_api = `${this.server_domain_endpoint}/walls`;
  floors_api = `${this.server_domain_endpoint}/floors`;
  vendors = `${this.server_domain_endpoint}/vendors`;
  forms = `${this.server_domain_endpoint}/filter-forms`;
  affiliates_links = `${this.server_domain_endpoint}/admin/affiliates`;
  affiliates_incomes = `${this.server_domain_endpoint}/admin/affiliates/income`;
  add_floors_tile = `${this.server_domain_endpoint}/floors-material`;
  filter_floor_tiles = `${this.server_domain_endpoint}/filter-floor-tiles`;
  filter_wall_colors = `${this.server_domain_endpoint}/filter-wall-colors`;
  preprocess_for_new_walls = `${this.server_domain_endpoint}/preprocess-walls`;
  preprocess_for_new_floors = `${this.server_domain_endpoint}/preprocess-floors`;
  wall_in_preprocess = `${this.server_domain_endpoint}/wall-in-preprocess`;
  floor_in_preprocess = `${this.server_domain_endpoint}/floor-in-preprocess`;
  space = `${this.server_domain_endpoint}/space`;
  countries_for_filter = `${this.server_domain_endpoint}/countries`;
  add_credit_card = `${this.server_domain_endpoint}/save-credit-card`;
  delete_card = `${this.server_domain_endpoint}/credit-cards`;
  do_charge = `${this.server_domain_endpoint}/charge`;
  all_requests = `${this.server_domain_endpoint}/requests`;
  allBackendErrors = `${this.server_domain_endpoint}/errors`;
  zoom_floor = `${this.server_domain_endpoint}/transform-floor`;
  debugging_history = `${this.server_domain_endpoint}/admin/test/integration`;
  server_restart = `${this.server_domain_endpoint}/restart`;
  testing_mode = `${this.server_domain_endpoint}/admin/state/testing-mode`;
  impression = `${this.server_domain_endpoint}/impressions`;
  click = `${this.server_domain_endpoint}/clicks`;

  get_server_domain(with_api) {
    return with_api ? this.server_domain_endpoint : this.server_domain;
  }
  login() {
    return this.log_in;
  }
  walls() {
    return this.walls_api;
  }
  floors() {
    return this.floors_api;
  }
  get_vendors() {
    return this.vendors;
  }
  get_forms() {
    return this.forms;
  }
  get_affiliates_links() {
    return this.affiliates_links;
  }
  get_affiliates_incomes() {
    return this.affiliates_incomes;
  }
  add_floor_tile() {
    return this.add_floors_tile;
  }
  get_filter_floor_tiles() {
    return this.filter_floor_tiles;
  }
  get_filter_wall_colors() {
    return this.filter_wall_colors;
  }
  preprocess_walls() {
    return this.preprocess_for_new_walls;
  }
  preprocess_floors() {
    return this.preprocess_for_new_floors;
  }
  walls_in_preprocess() {
    return this.wall_in_preprocess;
  }
  floors_in_preprocess() {
    return this.floor_in_preprocess;
  }
  spaces_info() {
    return this.space;
  }
  countries() {
    return this.countries_for_filter;
  }
  save_credit_card() {
    return this.add_credit_card;
  }
  delete_credit_card() {
    return this.delete_card;
  }
  charge() {
    return this.do_charge;
  }
  requests() {
    return this.all_requests;
  }
  backendErrors() {
    return this.allBackendErrors;
  }
  transform_floor() {
    return this.zoom_floor;
  }
  debuggingHistory() {
    return this.debugging_history;
  }
  restartServer() {
    return this.server_restart;
  }
  testingMode() {
    return this.testing_mode;
  }
  impressions() {
    return this.impression;
  }
  clicks() {
    return this.click;
  }
}

export default new Service();
