import React, { useEffect } from "react";
import { Modal, Table, Tooltip } from "antd";
import "./modal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getClicks,
  getImpressions,
} from "../../Redux/Actions/transferDataAction";
import { useLocation } from "react-router-dom";
import { activeTab } from "../../config";

const ViewImpressionsAndClicksInfo = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state.common);
  const { auth_info, loading, impressions, clicks } = state;
  const productType =
    activeTab(location.pathname) === "floors" ? "FLOORTILE" : "WALLCOLOR";
  const title =
    open.type === "impressionsCount"
      ? "impressions"
      : open.type === "clicksCount"
      ? "clicks"
      : open.type;
  //TODO decor and rug change functionality
  const handleOk = () => {
    setOpen();
  };
  const handleCancel = () => {
    setOpen();
  };
  useEffect(() => {
    if (open.type === "impressionsCount") {
      dispatch(getImpressions({ productType, productId: open.id }));
    } else {
      dispatch(getClicks({ productType, productId: open.id }));
    }
  }, []);
  const dataForMap = open.type === "impressionsCount" ? impressions : clicks;
  const dataSource = [];
  dataForMap.forEach((item, index) => {
    if (item) {
      item.key = item.id ? item.id : `${index + 1}`;
      dataSource.push(item);
    }
  });
  let columns = [
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: "Interaction Source",
      dataIndex: "interactionSource",
    },
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      render: (record) => {
        return <Tooltip title="UTC time Zone">{record}</Tooltip>;
      },
    },
    {
      title: "ip Address",
      dataIndex: "ipAddress",
    },
    {
      title: "Location",
      dataIndex: "city",
    },
  ];
  const showColumns = (columns, isImpression) => {
    if (auth_info.isVendor) {
      columns = columns.filter(
        (item) =>
          item.title !== "ID" &&
          item.title !== "ip Address" &&
          // item.title !== "Location" &&
          item.title !== "Interaction Source"
      );
    } else {
      if (!isImpression) {
        columns = columns.filter((item) => item.title !== "Interaction Source");
      }
    }

    return [...columns];
  };
  columns = showColumns(columns, open.type === "impressionsCount");
  return (
    <div>
      <Modal
        title={`View ${title} for ${open.name}`}
        open={open}
        width={900}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={""}
      >
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={loading[title]}
          bordered
          size={"small"}
        />
      </Modal>
    </div>
  );
};

export default ViewImpressionsAndClicksInfo;
