import React, { useRef } from "react";
import { Button } from "antd";
import moment from "moment";
import { set_is_modal, setFilterParams } from "./Redux/Actions/commonAction";
import Auth from "./Components/auth/auth";
import Vendors from "./Components/vendors/vendors";
import Walls from "./Components/walls/walls";
import Floors from "./Components/floors/floors";
import Rugs from "./Components/rugs/rugs";
import WallDecor from "./Components/wallDecor/wallDecor";
import FloorDecors from "./Components/floorDecors/floorDecors";
import Forms from "./Components/forms/forms";
import Logs from "./Components/logs/logs";
import TableDecors from "./Components/tableDecors/tableDecors";
import { CloudUploadOutlined } from "@ant-design/icons";
import { get_filter_walls } from "./Redux/Actions/wall";
import { get_filter_floors } from "./Redux/Actions/floor";
import { getAllRugs } from "./Redux/Actions/rugs";
import { getDecors } from "./Redux/Actions/decor";
import { get_forms } from "./Redux/Actions/transferDataAction";
import AffiliateLinks from "./Components/affiliateLinks/affiliateLinks";
import AffiliateIncomes from "./Components/affiliateIncome/affiliateIncomes";

export const base64ToBinary = async (url, filename) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename);
};
export const changeFileName = () => {
  const d = new Date();
  return `${d.getFullYear()}-${d.getMonth()}-${d.getDate()}_${d.getHours()}-${d.getMinutes()}-${d.getSeconds()}${Math.floor(
    Math.random() * 50000
  )}.jpg`;
};
export const fullUrlToFileName = (url) => {
  return url.slice(
    url.length - url.split("").reverse().join("").indexOf("/"), //reverse string and find last "/"
    url.length - url.split("").reverse().join("").indexOf(".") - 1 // reverse string and find last "."
  );
};
export const yearAndMonthForAddCard = (value) => {
  const yearAndMonth = {
    month: value.slice(
      value.length - value.split("").reverse().join("").indexOf("/"),
      value.length
    ),
    year: value.slice(
      0,
      value.length - value.split("").join("").indexOf("/") + 1
    ),
  };
  const d = new Date();
  const yearNow = d.getFullYear();
  const monthNow = d.getMonth() + 1;
  if (+yearAndMonth.year > +yearNow) {
    return yearAndMonth;
  } else if (
    +yearAndMonth.year === +yearNow &&
    +yearAndMonth.month > +monthNow
  ) {
    return yearAndMonth;
  } else {
    return null;
  }
};
export const datePickerValueOnlyDate = (date) => {
  const checkDate = JSON.parse(JSON.stringify(date));
  return moment(checkDate).format("YYYY-MM-DD");
};
export const initialParamsForFilter = {
  city: "",
  ipAddress: "",
  startDate: "",
  country: "United States",
  endDate: "",
  vendorID: "",
};
export const logsChartOptions = [
  { label: "last 1 month", value: 1 },
  { label: "last 2 months", value: 2 },
  { label: "last 3 months", value: 3 },
  { label: "last 4 months", value: 4 },
];
export const isFiltered = (params) => {
  if (params?.country !== "United States") {
    return true;
  }
  for (const property in params) {
    if (params[property] && property !== "country") {
      return true;
    }
  }
  return false;
};
export const calculateTotal = (data, label) => {
  let total = 0;
  data.forEach((item) => {
    if (item[label]) {
      if (
        label === "cost" ||
        label === "price" ||
        label === "impressionsCount" ||
        label === "clicksCount"
      ) {
        total = total + item[label];
      } else if (label === "name") {
        total = total + 1;
      } else {
        total = total + item[label].length;
      }
    }
  });
  return total;
};
export const showColumns = (columns, vendor) => {
  if (!vendor) {
    columns = columns.filter((item) => item.forVendor);
  }
  return [...columns];
};
export const tilesCount = 10;
export const queryParamsForCommonFilter = (data) => {
  const city = data.city ? `city=${data.city}&` : "";
  const country = data.country ? `country=${data.country}&` : "";
  const vendorID = data.vendorID ? `vendorID=${data.vendorID}&` : "";
  const ipAddress = data.ipAddress ? `ipAddress=${data.ipAddress}&` : "";
  const startDate = data.startDate
    ? `startDate=${datePickerValueOnlyDate(data.startDate)}&`
    : "";
  const endDate = data.endDate
    ? `endDate=${datePickerValueOnlyDate(data.endDate)}`
    : "";
  let params = city + country + vendorID + ipAddress + startDate + endDate;
  return params[params.length - 1] === "&"
    ? params.slice(0, params.length - 1)
    : params;
};
export const showDetails = (record, type, dispatch) => {
  let data;
  if (record === "records") {
    data = record[type]?.length;
  } else {
    data = record[type];
  }
  return (
    <div
      className={"impressionsRow"}
      style={{
        cursor: data ? "pointer" : "default",
      }}
    >
      <span>{data}</span>
      {data ? (
        <Button
          size={"small"}
          onClick={() => {
            dispatch(
              set_is_modal({
                open: {
                  ...record,
                  type,
                },
                type:
                  type === "records"
                    ? "recordsDetail"
                    : "impressions_and_clicks",
              })
            );
          }}
        >
          View
        </Button>
      ) : (
        ""
      )}
    </div>
  );
};
export const validateMaterialCode = (materials, value) => {
  let validate = true;
  materials.forEach((material) => {
    if (material.code === value) {
      validate = false;
    }
  });
  return validate;
};
export const optionsForMonth = () => {
  let arr = [];
  for (let i = 1; i <= 12; i++) {
    arr.push({ label: i, value: i });
  }
  return arr;
};
export const optionsForYear = () => {
  let arr = [];
  for (
    let i = new Date().getFullYear();
    i <= new Date().getFullYear() + 10;
    i++
  ) {
    arr.push({ label: i, value: i });
  }
  return arr;
};
const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const changeResponseDate = (data, type) => {
  const arr = [];
  if (type === "walls") {
    data.forEach((item) => {
      arr.push({
        ...item,
        key: item.id,
        color: item.hexCode,
        url: item.urlOnVendorPage,
      });
    });
  } else if (type === "floors") {
    data.forEach((item) => {
      arr.push({
        ...item,
        key: item.id,
        material: item.thumbnailURL,
        url: item.urlOnVendorPage,
        tiles: item.materials,
      });
    });
  } else if (type === "vendors" || type === "backendErrors") {
    data.forEach((item) => {
      arr.push({
        ...item,
        key: item.id,
      });
    });
  } else if (type === "forms") {
    data.forEach((item) => {
      arr.push({ ...item, key: item.id, dataAndTime: item.timestamp });
    });
  } else if (type === "requests") {
    data.forEach((item) => {
      const time =
        item.statusCompleted && item.taskAddedToQueue
          ? (
              (new Date(item.statusCompleted)?.getTime() -
                new Date(item.taskAddedToQueue)?.getTime()) /
              1000
            )?.toFixed(2)
          : null;
      arr.push({
        ...item,
        key: item.id,
        dateFormatForChart: `${new Date(item.taskAddedToQueue).getDate()}/${
          monthNames[new Date(item.taskAddedToQueue).getMonth()]
        }`,
        time: time ? time : "",
      });
    });
  } else if (type === "affiliateLinks") {
    data.forEach((item) => {
      arr.push({ key: item.id, code: item.key, ratio: item.ratio });
    });
  } else if (type === "affiliateIncomes") {
    data.forEach((item) => {
      arr.push({ ...item, key: item.affiliateId });
    });
  }

  return arr;
};

export const vendorTypes = [
  { label: "WALL_PAINT", value: "WALL_PAINT", type: "wall" },
  { label: "FLOOR_CARPET", value: "FLOOR_CARPET", type: "carpet" },
  { label: "FLOOR_TILE", value: "FLOOR_TILE", type: "tile" },
  { label: "FLOOR_WOOD", value: "FLOOR_WOOD", type: "wood" },
  { label: "FLOOR_VINYL", value: "FLOOR_VINYL", type: "wood" },
  { label: "FLOOR_LAMINATE", value: "FLOOR_LAMINATE", type: "wood" },
  { label: "RUG", value: "RUG", type: "rug" },
  { label: "WALLDECOR_ARTWORK", value: "WALLDECOR_ARTWORK", type: "walldecor" },
  { label: "WALLDECOR_MIRROR", value: "WALLDECOR_MIRROR", type: "walldecor" },
  { label: "WALLDECOR_CLOCK", value: "WALLDECOR_CLOCK", type: "walldecor" },
  {
    label: "FLOORDECOR_PLANT",
    value: "FLOORDECOR_PLANT",
    type: "floordecor",
  },
  { label: "FLOORDECOR_LAMP", value: "FLOORDECOR_LAMP", type: "floordecor" },
  {
    label: "TABLEDECOR_BASKET",
    value: "TABLEDECOR_BASKET",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_VASE",
    value: "TABLEDECOR_VASE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_FIGURINE",
    value: "TABLEDECOR_FIGURINE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_LANTERN",
    value: "TABLEDECOR_LANTERN",
    type: "tabledecor",
  },
  { label: "TABLEDECOR_LAMP", value: "TABLEDECOR_LAMP", type: "tabledecor" },
  {
    label: "TABLEDECOR_DECORATIVESPHERE",
    value: "TABLEDECOR_DECORATIVESPHERE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_CLOCK",
    value: "TABLEDECOR_CLOCK",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_CROSS",
    value: "TABLEDECOR_CROSS",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_CANDLE",
    value: "TABLEDECOR_CANDLE",
    type: "tabledecor",
  },
  {
    label: "TABLEDECOR_POTTEDPLANT",
    value: "TABLEDECOR_POTTEDPLANT",
    type: "tabledecor",
  },
];
export const wallTypes = [
  { label: "Color", value: "color" },
  { label: "Wallpaper", value: "wallpaper" },
];
export const checkSessionStorage = (filterParams, tablesPageInfo) => {
  if (!sessionStorage.getItem("filterParams")) {
    sessionStorage.setItem("filterParams", JSON.stringify(filterParams));
  }
  if (!sessionStorage.getItem("tablesPageInfo")) {
    sessionStorage.setItem("tablesPageInfo", JSON.stringify(tablesPageInfo));
  }
};
export const commonFilter = (page, forRemove) => {
  const params = page === "vendors" ? [] : { ...initialParamsForFilter };
  if (forRemove) {
    return params;
  } else {
    return sessionStorage.getItem("filterParams")
      ? JSON.parse(sessionStorage.getItem("filterParams"))[page]
      : params;
  }
};
export const sewingPatternOptions = [
  { label: "Staggered (Random)", value: "staggered" },
  { label: "Checkerboard (Stack)", value: "checkerboard" },
  { label: "Flipped Checkerboard", value: "flipped" },
  { label: "Gap Checkerboard", value: "gap_checkboard" },
  { label: "1/2 Offset", value: "OFFSET2" },
  { label: "1/3 Offset", value: "OFFSET3" },
];
export const defaultPaginationInfo = {
  current: 1,
  pageSize: 10,
};
export const activeTab = (pathname) => {
  return pathname.slice(1, pathname.length);
};

export const allRoutes = [
  { id: 1, path: "/auth", element: <Auth />, name: "Auth" },
  { id: 2, path: "/vendors", element: <Vendors />, name: "Vendors" },
  { id: 3, path: "/walls", element: <Walls />, name: "Walls" },
  { id: 4, path: "/floors", element: <Floors />, name: "Floors" },
  { id: 5, path: "/rugs", element: <Rugs />, name: "Rugs" },
  { id: 6, path: "/wallDecors", element: <WallDecor />, name: "Wall Decors" },
  {
    id: 7,
    path: "/floorDecors",
    element: <FloorDecors />,
    name: "Floor Decors",
  },
  {
    id: 8,
    path: "/tableDecors",
    element: <TableDecors />,
    name: "Table Decors",
  },
  { id: 9, path: "/forms", element: <Forms />, name: "Forms" },
  {
    id: 10,
    path: "/affiliateLinks",
    element: <AffiliateLinks />,
    name: "Affiliate Links",
  },
  {
    id: 11,
    path: "/affiliateIncomes",
    element: <AffiliateIncomes />,
    name: "Affiliate Incomes",
  },
  { id: 12, path: "/logs", element: <Logs />, name: "Logs" },
];
export const checkRoute = (pathname, navigate) => {
  if (
    !allRoutes.find(
      (route) => route.path === pathname && route.path !== "/auth"
    )
  ) {
    navigate("/walls");
  }
};
export const logsNavigation = [
  { id: 1, name: "Charts", activeTab: "charts" },
  { id: 2, name: "Table", activeTab: "table" },
  { id: 3, name: "Still Photo", activeTab: "stillphoto" },
  { id: 4, name: "Backend Errors", activeTab: "backendErrors" },
  { id: 5, name: "Debugging", activeTab: "debuggingInfo" },
];
export const chartStateSeries = (data, dateArray, serieName, type) => {
  let arr = [];
  dateArray.forEach((date, index) => {
    arr.push([]);
    data.forEach((item) => {
      if (item.type === serieName) {
        if (item.dateFormatForChart === date) {
          if (type === "interval" && !item.errorStackTrace) {
            if (
              (new Date(item.statusCompleted) -
                new Date(item.taskAddedToQueue)) /
                1000 >
              5
            )
              arr[index].push(
                (new Date(item.statusCompleted) -
                  new Date(item.taskAddedToQueue)) /
                  1000
              );
          } else if (
            (type === "globalInterval" || type === "maximum") &&
            !item.errorStackTrace
          ) {
            arr[index].push(
              (new Date(item.statusCompleted) -
                new Date(item.taskAddedToQueue)) /
                1000
            );
          } else if (type === "global") {
            arr[index].push(1);
          }
        }
      } else if (serieName === "ERROR" && item.status === serieName) {
        if (item.dateFormatForChart === date && type === "global") {
          arr[index].push(1);
        }
      }
    });
  });
  if (type === "interval" || type === "globalInterval" || type === "maximum") {
    let newArr = [];
    arr.forEach((item) => {
      let x = 0;
      item.forEach((extraItem) => {
        if (item) {
          x = x + +extraItem;
        }
      });
      if (type === "maximum") {
        newArr.push(
          item.length
            ? Math.max(...item.map((e) => (e > 0 ? e : 0))).toFixed(1) + 1
            : 0
        );
      } else {
        newArr.push(x / item.length > 0 ? (x / item.length).toFixed(1) : 0);
      }
    });
    return newArr;
  } else {
    return arr.map((item) => item.length);
  }
};
export const TileInput = ({
  count,
  materials,
  materialFiles,
  setMaterials,
  setMaterialFiles,
  floorGlobalType,
  handler_sphere,
}) => {
  const fileInputRef = useRef();
  return (
    <div className={"selectFile"} style={{ marginLeft: "20%" }}>
      <label htmlFor={`button-file${count}`}>
        <input
          accept=".png, .jpg, .jpeg, .svg"
          id={`button-file${count}`}
          style={{ display: "none" }}
          ref={fileInputRef}
          type="file"
          onChange={(e) => handler_sphere(e, "material", count)}
        />
        {materials[count] ? (
          <div>
            <img
              src={materials[count]}
              alt="uploaded logo"
              className={"reloadImage"}
            />
          </div>
        ) : (
          <CloudUploadOutlined style={{ fontSize: "200%" }} />
        )}
      </label>
      {materials[count] && (
        <span
          className={"imageX"}
          onClick={() => {
            materialFiles.splice(count, 1);
            materials.splice(count, 1);
            setMaterials([...materials]);
            setMaterialFiles([...materialFiles]);
            fileInputRef.current.value = "";
          }}
        >
          x
        </span>
      )}
      {(!floorGlobalType || floorGlobalType === "carpet") &&
        !materials[count] && (
          <i style={{ marginLeft: "20px" }}>Select Material</i>
        )}
    </div>
  );
};
export const vendorTypetoGlobalType = (type) => {
  return type
    .slice(0, type.length - type.split("").reverse().join("").indexOf("_") - 1)
    .toLowerCase();
};
export const optionForVendor = (types, pathname) => {
  return vendorTypes.filter(
    (item) =>
      types?.indexOf(item) &&
      pathname.slice(0, pathname.length - 1) ===
        vendorTypetoGlobalType(item.label)
  );
};
export const vendorAndTypeOptions = (vendors, pathname, isType) => {
  const name = pathname.slice(0, pathname.length - 1).toLowerCase();
  const types = vendorTypes.filter(
    (item) => vendorTypetoGlobalType(item.label) === name
  );
  if (isType) {
    return types;
  } else {
    const onlyTypes = [];
    types.forEach((types) => onlyTypes.push(types.label));
    const optionsForVendors = [];
    vendors?.forEach((vendor) => {
      vendor.type?.forEach((item) => {
        if (
          (onlyTypes.indexOf(item) >= 0 || pathname === "forms") &&
          !vendor.paused
        ) {
          optionsForVendors.push({
            label: vendor.name,
            value: vendor.key,
            types: vendor.type,
          });
        }
      });
    });
    return Object.values(
      optionsForVendors.reduce(
        (acc, cur) => Object.assign(acc, { [cur.value]: cur }),
        {}
      )
    );
  }
};
export const subTypeValue = (value) => {
  return value.slice(
    value.length - value.split("").reverse().join("").indexOf("_"),
    value.length
  );
};
export const checkVendorType = (route, types) => {
  if (types === "ADMIN") {
    return true;
  } else {
    const partName = activeTab(route)
      .toUpperCase()
      .slice(0, activeTab(route).length - 1);
    return types?.find((type) => {
      if (vendorTypetoGlobalType(type) === "walldecor" && route === "/walls") {
        return null;
      } else if (
        vendorTypetoGlobalType(type) === "floordecor" &&
        route === "/floors"
      ) {
        return null;
      } else {
        return type.includes(partName);
      }
    });
  }
};
export const checkFilterRequests = (dispatch, filterParams, pathname) => {
  dispatch(setFilterParams({ ...filterParams }));
  if (pathname === "/walls") {
    dispatch(get_filter_walls());
  } else if (pathname === "/floors") {
    dispatch(get_filter_floors());
  } else if (pathname === "/rugs") {
    dispatch(getAllRugs());
  } else if (pathname === "/wallDecors") {
    dispatch(getDecors("wall"));
  } else if (pathname === "/floorDecors") {
    dispatch(getDecors("floor"));
  } else if (pathname === "/tableDecors") {
    dispatch(getDecors("table"));
  } else if (pathname === "/forms") {
    dispatch(get_forms());
  }
};

//validation utils
export const validateURL = (rule, value, callback) => {
  if (!value || /^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
    callback();
  } else {
    callback("Please enter a valid URL");
  }
};
export const validateNumber = (rule, value, callback) => {
  if (!value || /^\d+$/.test(value)) {
    callback();
  } else {
    callback("Please enter only digits");
  }
};
