import React, { useEffect, useRef, useState } from "react";
import { Button, Switch, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  set_is_modal,
  set_min_width_header,
  set_tablesPageInfo,
} from "../../Redux/Actions/commonAction";
import { get_vendors, pause_vendor } from "../../Redux/Actions/vendor";
import {
  DeleteColumnOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { calculateTotal } from "../../config";

const Vendors = () => {
  const state = useSelector((state) => state.common);
  const { vendors, loading, tablesPageInfo, auth_info, filterParams } = state;
  const dispatch = useDispatch();
  const tableRef = useRef(null);

  const [vendorsDataSource, setVendorsDataSource] = useState([]);
  const tablePageOnChange = (e) => {
    dispatch(set_tablesPageInfo({ ...tablesPageInfo, vendors: e }));
  };
  useEffect(() => {
    if (vendors.length) {
      let arr = [];
      vendors.forEach((item) => {
        if (filterParams["vendors"]?.length) {
          item.type.forEach((extraItem) => {
            if (filterParams["vendors"]?.indexOf(extraItem) >= 0) {
              arr.push(item);
            }
          });
        } else {
          arr.push(item);
        }
      });
      setVendorsDataSource([
        ...Object.values(
          arr.reduce((acc, cur) => Object.assign(acc, { [cur.key]: cur }), {})
        ),
      ]);
    }
  }, [filterParams, vendors]);
  const vendorsColumns = [
    {
      title: "ID",
      dataIndex: "key",
      sorter: (a, b) => b.key - a.key,
    },
    {
      title: (
        <span>
          Name <br /> Total ({calculateTotal(vendors, "name")})
        </span>
      ),
      dataIndex: "name",
    },
    {
      title: "Amount Used",
      dataIndex: "",
      render: (record) => {
        return (
          <div className={"estimatedDueField"}>
            <span>${record.dueCost.toFixed(2)}</span>
            <br />
            {record.stripeCreditCardID ? (
              <Button
                onClick={() =>
                  dispatch(set_is_modal({ open: record, type: "charge" }))
                }
              >
                Charge
              </Button>
            ) : (
              "No Card"
            )}
          </div>
        );
      },
    },
    {
      title: (
        <span>
          Type, <br />
          Material Count
        </span>
      ),
      dataIndex: "",
      render: (record) => {
        return (
          <>
            <p>
              {record.type?.map(
                (item) =>
                  item +
                  (item !== record.type[record.type.length - 1] ? ", " : "")
              )}
            </p>
            <hr />
            {record.materialCount}
          </>
        );
      },
    },
    {
      title: (
        <span>
          Status, <br />
          Impression Limit
        </span>
      ),
      dataIndex: "",
      render: (record) => {
        return (
          <>
            <Switch
              checked={record.paused}
              checkedChildren="Paused"
              unCheckedChildren="Live"
              onChange={() => {
                dispatch(pause_vendor(record.key));
              }}
            />
            <br />
            {record.impressionLimit}
          </>
        );
      },
    },
    {
      title: "Monthly Budget",
      dataIndex: "monthlyBudget",
      render: (record) => record,
    },
    {
      title: "URL",
      dataIndex: "",
      render: (record) => (
        <>
          <a href={record} target={"blank"}>
            {record.url}
          </a>
          <br />
          Site Visit - {record.visitCount}
        </>
      ),
    },
    {
      title: "Logo",
      dataIndex: "logo",
      render: (record) => (
        <img
          src={record}
          style={{ cursor: "pointer" }}
          onClick={() =>
            record &&
            dispatch(set_is_modal({ open: record, type: "view_photo" }))
          }
          alt="logo"
          width={30}
        />
      ),
    },
    {
      title: "Password",
      dataIndex: "password",
    },
    {
      title: "Actions",
      dataIndex: "",
      render: (record) => (
        <div className="actions">
          {record.stripeCreditCardID && (
            <Tooltip title="Delete Card">
              <DeleteColumnOutlined
                onClick={() => {
                  dispatch(set_is_modal({ open: record.key, type: "delete" }));
                  // dispatch(set_active_item_for_change(record.key));
                }}
              />
            </Tooltip>
          )}

          <Tooltip title="Edit">
            <EditOutlined
              onClick={() => {
                dispatch(set_is_modal({ open: record, type: "edit" }));
                // dispatch(set_active_item_for_change(record));
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <DeleteOutlined
              onClick={() => {
                dispatch(set_is_modal({ open: record, type: "delete" }));
              }}
            />
          </Tooltip>
        </div>
      ),
    },
    get_vendors,
  ];

  useEffect(() => {
    if (!vendors.length && !auth_info.isVendor) {
      dispatch(get_vendors());
    }
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      set_min_width_header(
        tableRef?.current?.children[0].children[0].children[0].children[0]
          .children[0].children[0]?.offsetWidth
      )
    );
  }, [tableRef, dispatch]);
  return (
    <div>
      <Table
        ref={tableRef}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-light" : "table-row-dark"
        }
        size={"small"}
        className={"globalTable"}
        loading={loading.vendors}
        dataSource={[...vendorsDataSource]}
        columns={vendorsColumns}
        bordered
        onChange={tablePageOnChange}
        pagination={tablesPageInfo.vendors}
      />
    </div>
  );
};

export default Vendors;
